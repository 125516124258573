<template>
    <v-app>
            <div class="mb-4" style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 96px;
            height: 32px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #34C873;
            border-radius: 4px;
            word-break:normal;
            background: #DAEEE8;">
                Blocks
            </div>
            <div style="text-align: left;"> 
                <div class="mb-4 d-flex justify-space-between">
                    <div style="width: 49%">
                        <div class="mb-3">
                            <span>Add Title</span>
                            <span style="color: #BDBDBD;">(English)</span>
                        </div>
                        <div 
                        style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field 
                            :rules="!editBlockFlagProps ? [noSameBlockName(blockTitleEN)]: []"
                            v-model="blockTitleEN"
                            filled placeholder="Promotional Block"></v-text-field>
                        </div>
                    </div>
                    <div style="width: 49%">
                        <div class="mb-3">
                            <span>Add Title</span>
                            <span style="color: #EB5757;">(Burmese)</span>
                        </div>
                        <div style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field 
                            v-model="blockTitleMM" filled placeholder="Promotional Block"></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="mb-4 d-flex justify-space-between">
                    <div style="width: 49%">
                        <div class="mb-3">
                            Add Description
                            <span>
                                (Optional)
                            </span>
                            <span style="color: #BDBDBD;">(English)</span>
                        </div>
                        <div style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field
                            v-model="blockDescEN" filled placeholder="Promotional Block"></v-text-field>
                        </div>
                    </div>
                    <div style="width: 49%">
                        <div class="mb-3">
                            Add Description
                            <span>
                                (Optional)
                            </span>
                            <span style="color: #EB5757;">(Burmese)</span>
                        </div>
                        <div style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field
                            v-model="blockDescMM" filled placeholder="Promotional Block"></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="mb-4 d-flex flex-column">
                    <div class="mr-2" style="margin-top:auto;margin-bottom:auto;"> 
                        <span>Add Background Color</span>
                    </div>
                    <div class="d-flex" style="margin-top:auto;margin-bottom:auto;">
                        <div class="colorClass mr-4" :class="blueColorFlag ? ' colorClassSelected' : 'colorClassNotSelected'" @click="selectBackGroundColor('blue')">
                            <span style="width:48px; height: 48px;border-radius: 4px; background: #EAF4FF"></span>
                                Blue
                            <img :src="tickBlue" v-if="blueColorFlag"/>
                        </div>
                        <div class="colorClass mr-4" :class="greyColorFlag ? 'colorClassSelected' : 'colorClassNotSelected'" @click="selectBackGroundColor('grey')">
                            <span style="width:48px; height: 48px;border-radius: 4px; background: #EDEDED"></span>
                                Grey
                            <img :src="tickBlue" v-if="greyColorFlag"/>
                        </div>
                        <div class="colorClass mr-4" :class="whiteColorFlag ? 'colorClassSelected' : 'colorClassNotSelected'" @click="selectBackGroundColor('white')">
                            <span style="width:48px; height: 48px; border: 1px solid #E0E0E0; border-radius: 4px;background: #FFFFFF"></span>
                                White
                            <img :src="tickBlue" v-if="whiteColorFlag"/>    
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div
                        class="inter400Normal mb-3"
                        style="width: 96px;
                        height: 19px;
                        font-size: 16px;
                        line-height: 19px;
                        color: #333333;
                        flex: none;
                        order: 0;
                        flex-grow: 0;"
                    >
                        Block Type
                    </div>
                    <div
                    style="display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 8px;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;"
                    >
                        <v-autocomplete 
                        filled
                        v-model="blockType"
                        :items="blockTypes"
                        required
                        @change="changeBlockType(blockType)"
                        />
                    </div>
                    <div
                    style="display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 8px;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;"
                    v-if="blockType === 'Doctors' || blockType === 'DOCTORS'"
                    >
                        <v-autocomplete 
                        filled
                        v-model="blockTypeListing"
                        :items="blockTypeListings"
                        required
                        />
                    </div>
                </div>
            </div>
            <div 
            class="justify-space-between;width:1403px;"
            style="min-height:80px; border: 1px solid #E0E0E0; border-radius: 8px;box-sizing: border-box;"
            >
                <div>
                    <div class="d-flex flex-row my-2 ml-4">
                        No. Of Tabs
                    </div>
                    <div class="d-flex flex-row my-2" style="width:30%; justify-content: initial;">
                        <div 
                            class="sinMulCommon ml-4 clickable-pointer"
                            :class="singleFlag && !multipleFlag ? 'sinMulSelected': 'sinMulNotSelected'"
                            @click="sinMulChange()">
                            <span class="sinMulText mlra">Single</span>
                            <div class="mtba mlra" v-if="singleFlag && !multipleFlag">
                                <img :src="tickBlue" />
                            </div>
                        </div>
                        <div 
                            class="sinMulCommon ml-4 clickable-pointer" 
                            :class="!singleFlag && multipleFlag ? 'sinMulSelected': 'sinMulNotSelected'"
                            @click="mulSinChange()">
                            <span class="sinMulText">Multiple</span>
                            <span class="maxFont ml-2">(Max 5)</span>
                            <div  class="mtba mlra" v-if="!singleFlag && multipleFlag">
                                <img :src="tickBlue" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectCollectionFlag" class="ml-4">
                    <div class="d-flex flex-row my-2" style="justify-content: space-between;">
                        Select Collections
                    </div>
                    <div class="my-2" style="justify-content: space-between;">
                        <div class="mr-4">
                            <draggable style="width: 100%;" v-model="addTabList" v-bind="dragOptions">
                                <div  v-for="(item, index) in addTabList" :key="item.id" class="d-flex mb-2" :class="addNewTabHeight ? 'height225' : 'height76'">
                                    <div class="mr-2" style="cursor: move;">
                                        <img :src="draggableIcon" />
                                    </div>
                                    <div 
                                    class="draggable-item mr-2" 
                                    style="box-sizing: border-box;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    padding: 8px 16px;
                                    gap: 10px;
                                    width: 160px;
                                    height: 44px;
                                    background: #F4F6FA;
                                    border: 1px solid #48ACEF;
                                    border-radius: 8px;
                                    flex: none;
                                    order: 1;
                                    flex-grow: 0;"> 
                                        <span 
                                        style="
                                        color: #48ACEF;
                                        width: 44px;
                                        height: 19px;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 19px;
                                        border-radius: 4px;
                                        flex: none;
                                        order: 0;
                                        flex-grow: 0;"> 
                                        {{ item.name }}
                                        </span>
                                    </div>
                                    <div class="addNewTabCollectionStyle mr-2">
                                        <div style="width:100%;">
                                            <v-autocomplete
                                            filled
                                            v-model="collectionItemValue[index]"
                                            :items="collectionItems"
                                            item-text="catName"
                                            item-value="catID"
                                            @change="selectCollection(index)"
                                            required/>
                                        </div>
                                        <div style="width:100%;">
                                            <v-autocomplete
                                            filled
                                            v-model="collectionPreviewList[index]"
                                            :items="collectionPreviewListItems[index]"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            @change="selectCollectionPreview(index)"
                                            required/>
                                        </div>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        <div class="d-flex mr-4 clickable-pointer" @click="addNewTabBlock()" 
                            v-if="(singleFlag && addTabList.length < 1) || (multipleFlag && addTabList.length < 5)"> 
                            <div>
                                <img :src="draggableIcon" />
                            </div>
                            <div class="addNewTabStyle mr-2">
                                <img :src="addNewTab" />
                                <p
                                    style="margin-top: auto; 
                                    margin-bottom: auto;
                                    width: 103px;
                                    height: 19px;
                                    font-family: 'Inter';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #48ACEF;
                                    flex: none;
                                    order: 1;
                                    flex-grow: 0;"
                                >Add New Tab</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-4">
                <div class="mb-3" style="text-align: left;">Add Icon</div>
                <div 
                    style="
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        padding: 16px;
                        gap: 16px;
                        background: #F4F6FA;
                        border-radius: 6px;
                        "
                    >
                    <!-- height: 112px; 
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;
                    -->
                    <!--  :class="icon.showImage ? 'iconDivClassWidth120' : 'iconDivClassWidth80'" -->
                    <div @click="toggleImageFlag(index)" class="iconDivClass d-flex mx-2" v-for="(icon, index) of selectIcons" :key="index">
                        <img height="60%" width="60%" :src="icon.url" />
                        <img height="50%" width="50%" v-if="icon.showImage" :src="tickBlue" />
                    </div>
                </div>

            </div>
            <div class="d-flex flex-column justify-space-around" style="width: 100%;"> 
                <div class="d-flex flex-row" style="text-align: left">
                    <div class="mr-2" style="margin-top:auto;margin-bottom:auto;"> 
                        <span>Display Button</span>
                        <span>(Optional)</span>
                    </div>
                    <div style="margin-top:auto;margin-bottom:auto;"> 
                        <v-radio-group row v-model="displayJumpLinkFlag" @change="displayJumpLink()">
                            <v-radio label="Yes" value="Yes"></v-radio>
                            <v-radio label="No" value="No"></v-radio>
                        </v-radio-group>
                    </div>
                </div>
                <div 
                v-if="displayJumpLinkFlag === 'Yes'" 
                class="d-flex flex-column" 
                style="width: 100%; border: 1px solid #E0E0E0;border-radius: 8px;">
                    <div class="d-flex my-2 mx-4" style="justify-content: space-between;">
                        <div style="width: 49%">
                            <div style="text-align: left">
                                <span>Button Title</span>
                                <span>(English)</span>
                            </div>
                            <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 8px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;">
                                <v-text-field 
                                v-model="buttonTitleEN"
                                filled placeholder="Promotional Block"></v-text-field>
                            </div>
                        </div>
                        <div style="width: 49%">
                            <div style="text-align: left">
                                <span>Button Title</span>
                                <span style="color: #EB5757;">(Burmese)</span>
                            </div>
                            <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 8px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;">
                                <v-text-field 
                                v-model="buttonTitleMM"
                                filled placeholder="Promotional Block"></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex my-2 mx-4" style="justify-content: space-between;">
                        <div class="d-flex flex-column " style="width:49%; justify-content: space-evenly;">
                            <div class="" style="text-align: left;">
                                <span>Jump Link</span>
                            </div>
                            <div class=""> 
                                <v-autocomplete 
                                filled
                                v-model="jumpLink"
                                :items="routeNames"
                                required
                                @change="jumpLinkChanged()"
                                />
                            </div>
                        </div>
                        <div class="d-flex flex-column " style="width:49%; justify-content: space-evenly;" v-if="jumpLink == 'SpecializationsPage'">
                            <div class="mtba" style="text-align: left;">
                                <span v-if="jumpLink === 'SpecializationsPage'">Specialization Name</span>
                            </div>
                            <div class="mtba"> 
                                <v-autocomplete 
                                v-if="jumpLink === 'SpecializationsPage'"
                                item-text="name" 
                                item-value="_id" 
                                v-model="customURL" 
                                :items="customURLNames"
                                return-object
                                @change="customURLSelected()"
                                :disabled="jumpLink === 'SpecializationsPage' ? false : true"
                                filled  />
                            </div>
                        </div>
                        <div class="d-flex flex-row " style="width:49%; justify-content: space-evenly;" v-if="jumpLink == 'CatalogPage'">
                            <div class="mtba" style="text-align: left;">
                                <span v-if="jumpLink == 'CatalogPage'">Catalog Type</span>
                                <v-autocomplete 
                                v-if="jumpLink == 'CatalogPage'"
                                item-text="name" 
                                item-value="_id" 
                                v-model="catalogPageType" 
                                :items="CatalogPageTypes"
                                return-object
                                @change="customURLSelected()"
                                filled  />
                            </div>
                            <div v-if="jumpLink == 'CatalogPage'" class="mtba" style="text-align: left;">
                                <span v-if="catalogPageType == 'COLLECTIONS'">Collection Name</span>
                                <span v-if="catalogPageType == 'CATEGORIES'">Category Name</span>
                                <v-autocomplete 
                                v-if="jumpLink == 'CatalogPage' && (catalogPageType == 'COLLECTIONS' || catalogPageType == 'CATEGORIES')"
                                item-text="cat_name" 
                                item-value="cat_id" 
                                v-model="customURL" 
                                :items="customURLNames"
                                return-object
                                @change="customURLSelected()"
                                filled  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row my-2" style="width:100%; justify-content: space-between;"
            :class="activeStatus ? 'borderBlue': 'discardBorder'">
                        <div 
                        class="mtba mx-3"
                        :class="activeStatus ? 'colorBlue' : 'discardButtonFont'" 
                        style="font-family: 'Inter';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 19px;
                                height: 19px;
                                flex: none;
                                order: 0;
                                flex-grow: 0;"> 
                            <span>Active Status: </span>
                            <span>{{ activeStatus ? 'Active' : 'InActive' }}</span>
                        </div>
                        <div class="mtba"> 
                            <v-switch
                            v-model="activeStatus" 
                            required
                            />
                        </div>
            </div>
            <div> 
                <div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: space-between;">
                </div>
                <div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: end;">
                    <v-btn class="mx-3 saveDiscardButton discardButtonFont" color="#FFFFFF" @click="discardAddBlockDialog()">Discard</v-btn>

                    <v-btn :disabled="isDisabled()" class="mx-3 saveDiscardButton saveButtonFont" color="#219653" @click="saveBlockDetails()">Save</v-btn>
                </div>
            </div>
            <div v-if="displayDiscardDialog">
                <DiscardDialog
                    :displayDiscardDialogProps="displayDiscardDialog"
                    @closeDiscardDialog="closeDiscardDialog"
                ></DiscardDialog>
            </div>
    </v-app>
</template>

<script>
import axios from "axios";
import { axios_auth_instance_epharmacy_admin } from '../utils/axios_utils';
import router from '@/router';
import draggable from 'vuedraggable';
import DiscardDialog from '../components/DiscardConfirmationDialog.vue';
export default {
    name: 'AddBlockComponent',
    props:['iconObjects', 'editBlockFlagProps', 'editBlockDetails', 'orderNumber', 'promoBlockNameList', 'pageType', 'addBlockFlagProps'],
    components: {
        'draggable': draggable,
        'DiscardDialog': DiscardDialog,
    },
    computed: {
		noSameBlockName(value) {
			return (value) => {
                let returnFlag =  false;
                if(this.promoBlockNameList && this.promoBlockNameList.includes(value)) {
                    returnFlag = true;
                }
                if(returnFlag) return "Block Segment Name Already Exists";
                if (!value.trim()) return "Give a valid hero banner name";
                else return true;
            }
		},
        
    },
    data() {
        return {
            CatalogPageTypes: ["COLLECTIONS", "CATEGORIES"],
            displayDiscardDialog: false,
            customURL: '',
            customURLNames: [],
            customURLFlag: false,
            addTabList: [],
            cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
            tickBlue: 'tickBlue.svg',
            addNewTab: 'addNewTab.svg',
            draggableIcon: 'draggableIcon.svg',
            dragOptions: {
                animation: 200
            },
            blockDescEN: '',
            blockDescMM: '',
            blockTitleEN: '',
            blockTitleMM: '',
            selectedFile: null,
			jumpLink: '',
            routeNames: [],
            activeStatus: false,
            buttonTitleEN: '',
            buttonTitleMM: '',
            blockType: '',
            blockTypes: ['Doctors', 'Products', 'Collections'],
            blockTypeListings: ['LISTINGS-ONE-ROW', 'LISTINGS-TWO-ROWS'],
            blockTypeListing: '',
            addTabValue: '',
            singleFlag: false,
            multipleFlag: false,
            addNewTabHeight: false,
            collectionItemValue: [],
            collectionItemValueName: [],
            collectionItems: [],
            collectionPreviewList: [],
            collectionPreviewListItems: [],
            selectIcons: [],
            backgroundColor: '',
            selectCollectionFlag: false,
            selectedIconIndex: '',
            displayJumpLinkFlag: 'No',
            blueColorFlag: false,
            greyColorFlag: false,
            whiteColorFlag: false,
            displayJumpLinkDetails: {},
            iconFileName: '',
            catalogPageType: '',
        };
    },
    methods: {
        isDisabled() {
            return !(this.blockDescEN && this.blockDescMM && this.blockTitleEN && this.blockTitleMM && this.displayJumpLinkFlag 
            && (this.activeStatus == false || this.activeStatus == true) 
            && (this.displayJumpLinkFlag == 'Yes' ? this.buttonTitleEN && this.buttonTitleMM && this.jumpLink: true) 
            && (this.jumpLink == 'SpecializationsPage' ? this.customURL: true) 
            && (this.jumpLink == 'CatalogPage' ? this.catalogPageType: true) 
            && ((this.catalogPageType == 'COLLECTIONS' || this.catalogPageType == 'CATEGORIES') ? this.customURL: true) 
            && (this.addTabList.length > 0) && (this.selectedIconIndex || parseInt(this.selectedIconIndex) == 0) && this.backgroundColor && (this.collectionItemValue.length > 0) && (this.collectionPreviewList.length > 0));
        },
        async customURLSelected() {
            this.customURLFlag = true;
        },
        async jumpLinkChanged() {
            try {
                if (this.jumpLink === 'SpecializationsPage') {
                    this.customURLNames = [];
                    this.customURLFlag = true;
                    let readSuccessData = await axios.post(process.env.VUE_APP_BACKEND_URL + "/getSpecializationByPosition", {
                        token: this.$cookies.get("token"),
                        typeOfUser: "ADMIN"
                    });
                    if(readSuccessData && readSuccessData.data && readSuccessData.data.data) {
                        this.customURLNames = readSuccessData.data.data;
                    } else {
                        alert('Something went wrong');
                    }
                } else if (this.jumpLink === 'CatalogPage') {
                    this.customURLNames = [];
                    this.customURLFlag = true;
                    let readSuccessData = await axios_auth_instance_epharmacy_admin.post("/admin/getAllCategories", {});
                    if(readSuccessData && readSuccessData.data && readSuccessData.data.data && readSuccessData.data.data.Items && readSuccessData.data.data.Items.length > 0) {
                        this.customURLNames = readSuccessData.data.data.Items;
                    } else {
                        alert('Something went wrong');
                    }
                } else {
                    this.customURLFlag = false;
                    this.customURL = '';
                    this.catalogPageType = '';
                }
            } catch(error) {
                console.log('[ERROR] Error: ', error);
                alert('Something went wrong');
            }
        },
        closeDiscardDialog(value) {
            if(value.value) {
                this.$emit('discardAddBlockDialog', true);
            } else {
                this.displayDiscardDialog = false;
            }
        },
        async saveBlockDetails() {
            if(this.collectionItemValue.length > 0 && this.collectionPreviewList.length > 0) {
                if(this.displayJumpLinkFlag === 'Yes') {
                    this.displayJumpLinkDetails = {
                        buttonTitleEN: this.buttonTitleEN,
                        buttonTitleMM: this.buttonTitleMM,
                        jumpLink: this.jumpLink,
                        customURLFlag: this.customURLFlag,
                        catalogPageType: this.jumpLink === 'CatalogPage' ? this.catalogPageType : '',
                        customURL: this.customURLFlag ? this.customURL : '',
                    }
                }
                var collectionList = [];
                for(let ind in this.addTabList) {
                    collectionList.push({
                        id: this.addTabList[ind].id,
                        name: this.addTabList[ind].name,
                        collectionItemValue: this.collectionItemValue[ind],
                        collectionItemValueName: this.collectionItemValueName[ind],
                        collectionPreviewList: this.collectionPreviewList[ind]
                    })
                }
                
                var blockDataToBeSaved = {
                    pageType: this.pageType,
                    backgroundColor: this.backgroundColor,
                    addTabValue: this.addTabValue,
                    blockDescEN: this.blockDescEN,
                    blockDescMM: this.blockDescMM,
                    blockType: this.blockType,
                    blockTypeListing: (this.blockType === 'DOCTORS' || this.blockType === 'Doctors') ? this.blockTypeListing : '',
                    blockTitleEN: this.blockTitleEN,
                    blockTitleMM: this.blockTitleMM,
                    displayJumpLink: this.displayJumpLinkFlag === 'Yes' || this.displayJumpLinkFlag == 'Yes' ? true : false,
                    displayJumpLinkDetails: this.displayJumpLinkFlag == 'Yes' ? this.displayJumpLinkDetails : {},
                    activeStatus: this.activeStatus,
                    selectedIconIndex: this.selectedIconIndex,
                    selectedIconURL: this.selectedIconURL,
                    collectionList: collectionList,
                };
    
                if(this.$props.editBlockFlagProps) {
                    blockDataToBeSaved['shortID'] = this.shortID;
                    try {
                        let updateBlockResponse = await axios_auth_instance_epharmacy_admin.post("/admin/updateBlock", blockDataToBeSaved);
                    
                        if(updateBlockResponse) {
                            this.$emit('updateBlockResponseEmit', true);
                        } else {
                            // this.$cookies.remove('token');
                            // this.$router.push({
                            //     name: "AdminLogin",
                            // });
                        }
                    } catch (error) {
                        if(error && error.response && error.response.status == 401) {
                            window.alert("Sorry !! You are unauthorized !!");
                            this.$cookies.remove('token');
                            this.$router.push({
                                name: "AdminLogin",
                            });
                        }
                    }
                } else {
                    try {
                        let addBlockResponse = await axios_auth_instance_epharmacy_admin.post("/admin/addBlock", blockDataToBeSaved);
                    
                        if(addBlockResponse) {
                            this.$emit('addBlockResponseEmit', true);
                        } else {
                        // this.$cookies.remove('token');
                        // this.$router.push({
                        //     name: "AdminLogin",
                        // });
                        }
                    } catch (error) {
                        if(error && error.response && error.response.status == 401) {
                            window.alert("Sorry !! You are unauthorized !!");
                            this.$cookies.remove('token');
                            this.$router.push({
                                name: "AdminLogin",
                            });
                        }
                    }
                }
            } else {
                alert('Fill all the details');
            }
        },
        selectBackGroundColor(ref) {
            this.backgroundColor = ref;
            if(ref === 'blue') {
                this.blueColorFlag = true;
                this.greyColorFlag = false;
                this.whiteColorFlag = false;
            }
            if(ref === 'grey') {
                this.greyColorFlag = true;
                this.blueColorFlag = false;
                this.whiteColorFlag = false;   
            }
            if(ref === 'white') {
                this.whiteColorFlag = true;
                this.blueColorFlag = false;
                this.greyColorFlag = false;
            }
        },
        displayJumpLink() {
            if(this.displayJumpLinkFlag == 'Yes') {
                this.displayJumpLinkDetails = {
                    buttonTitleEN: '',
                    buttonTitleMM: '',
                };
            } else {
                this.displayJumpLinkDetails = {};
            }
        },
        toggleImageFlag(index) {
            for(let iconIndex in this.selectIcons) {
                if(parseInt(iconIndex) === index) {
                    this.selectIcons[iconIndex].showImage = !this.selectIcons[iconIndex].showImage;
                    if(this.selectIcons[iconIndex].showImage) {
                        this.selectedIconIndex = index;
                        this.selectedIconURL = this.selectIcons[iconIndex].url;
                    }
                } else {
                    this.selectIcons[iconIndex].showImage = false;
                }
            }

        },
        async selectCollectionPreview(index) {
        },
        async selectCollection(index) {
            let parentCategoryID = this.collectionItemValue[index];
            const selectedOption = this.collectionItems.find(option => option.catID === this.collectionItemValue[index]);
            if (selectedOption) {
                this.collectionItemValueName[index] = selectedOption.catName;
            }
            this.collectionPreviewList[index] = [];
            if(this.blockType === 'DOCTORS' || this.blockType === 'Doctors') {
                await this.getDoctorsBySpecializationList(parentCategoryID, index, this.collectionItemValueName[index]);
            } else if(this.blockType === 'PRODUCTS' || this.blockType === 'Products') {
                await this.getAvailableProductsByCategories(parentCategoryID, index, this.collectionItemValueName[index]);
            } else if(this.blockType === 'COLLECTIONS' || this.blockType === 'Collections'){
                await this.getAvailableProductsByCollections(parentCategoryID, index,this.collectionItemValueName[index]);
            }
        },
        addNewTabBlock() {
            this.addNewTabHeight = true;
            if(this.singleFlag) {
                if(this.addTabList.length > -1 && this.addTabList.length < 1) {
                    let tabCount = this.addTabList.length + 1;
                    this.addTabList.push({
                        id : tabCount,
                        name: String('Tab ' + tabCount)
                    });
                }
            }
            if(this.multipleFlag) {
                if(this.addTabList.length > -1 && this.addTabList.length < 5) {
                    let tabCount = this.addTabList.length + 1;
                    this.addTabList.push({
                        id : tabCount,
                        name: String('Tab ' + tabCount)
                    });
                }
            }
        },
        mulSinChange() {
            this.addTabList = [];
            this.collectionItemValue = [];
            this.collectionPreviewList = [];
            this.multipleFlag =  !this.multipleFlag;
            this.singleFlag =  false;
            if(this.multipleFlag) {
                this.addTabValue = 'Multiple';
                this.selectCollectionFlag = true;
            } else {
                this.addTabValue = '';
                this.selectCollectionFlag = false;
            }
        },
        sinMulChange() {
            this.addTabList = [];
            this.collectionItemValue = [];
            this.collectionPreviewList = [];
            this.singleFlag =  !this.singleFlag;
            this.multipleFlag =  false;
            if(this.singleFlag) {
                this.addTabValue = 'Single';
                this.selectCollectionFlag = true;
            }else {
                this.addTabValue = '';
                this.selectCollectionFlag = false;
            }
        },
        getRouteNames() {
            return router.options.routes
            .filter(route => route.visibility === true)
            .map(route => route.name);
        },
        discardAddBlockDialog() {
            this.displayDiscardDialog = true;
        },
        async getAllCategories() {
            this.collectionItems = [];
            try {
                let res = await axios_auth_instance_epharmacy_admin.post("/admin/getAllCategories", {});
                if(res && res.data && res.data.data && res.data.data.Items && res.data.data.Items.length > 0) {
                    let toGetCategory = res.data.data.Items;
                    for (let ind in toGetCategory) {
                        this.collectionItems.push({
                            'catID': toGetCategory[ind].cat_id,
                            'catName': toGetCategory[ind].cat_name
                        });
                    }
                } else {
                    // this.$cookies.remove('token');
                    // this.$router.push({
                    //     name: "AdminLogin",
                    // });
                }
            } catch (error) {
                if(error && error.response && error.response.status == 401) {
                    window.alert("Sorry !! You are unauthorized !!");
                    this.$cookies.remove('token');
                    this.$router.push({
                        name: "AdminLogin",
                    });
                }
            }
        },
        async getAllCollection() {
            this.collectionItems = [];
            try {
                let response = await axios_auth_instance_epharmacy_admin.get("/admin/getAllCollection", {});
                if (response && response.status === 200) {
                    let data = response.data;
                    if (data && Array.isArray(data.data) && data.data.length > 0) { 
                        this.collectionItems = data.data.map(item => ({
                            'catID': item.collection_id,
                            'catName': item.collection_name
                        }));
                    }
                } else {
                    // this.$cookies.remove('token');
                    // this.$router.push({
                    //     name: "AdminLogin",
                    // });
                }
            } catch (error) {
                if(error && error.response && error.response.status == 401) {
                    window.alert("Sorry !! You are unauthorized !!");
                    this.$cookies.remove('token');
                    this.$router.push({
                        name: "AdminLogin",
                    });
                }
            }
        },

        async changeBlockType(value) {
            this.collectionPreviewListItems = [];
            this.collectionItems = [];
            this.collectionPreviewList=[];
            this.collectionItemValue = [];
            if(value === 'DOCTORS' || value === 'Doctors') {
                await this.readAllSpecializations();
            } else if(value === 'PRODUCTS' || value === 'Products') {
                this.blockTypeListing = '';
                await this.getAllCategories();
            } else if(value === 'COLLECTIONS' || value ==='Collections'){
                this.blockTypeListing = '';
                await this.getAllCollection();
            }
        },
        async toPreSelectcollectionPreviewList(parentCategoryID, index, value) {
            if(value === 'DOCTORS' || value === 'Doctors') {
                await this.getDoctorsBySpecializationList(parentCategoryID, index);
            } else if(value === 'PRODUCTS' || value === 'Products') {
                await this.getAvailableProductsByCategories(parentCategoryID, index);
            } else if(value === 'COLLECTIONS' || value === 'Collections'){
                await this.getAvailableProductsByCollections(parentCategoryID, index);
            }
        },
        async getAvailableProductsByCategories(parentCategoryID, index) {
            this.collectionPreviewListItems[index] = [];
            try {
                let res = await axios_auth_instance_epharmacy_admin.post("/admin/getAvailableProductsByCategories", {
                    parentCategoryID: parentCategoryID
                });
                if(res && res.data && res.data.data && res.data.data.Items && res.data.data.Items.length > 0) {
                    let toGetCategory = res.data.data.Items;
                    var arr = [];
                    for (let ind in toGetCategory) {
                        arr.push({
                            'id': toGetCategory[ind].product_id,
                            'name' : toGetCategory[ind].product_name,
                            'icon': toGetCategory[ind].product_icon_image,
                            'description': toGetCategory[ind].description,
                            'price': toGetCategory[ind].list_price,
                        });
                    }
                    var mainArr = [];
                    for (var [idx, innerArr] of this.collectionPreviewListItems.entries()) {
                        if (idx == index) {
                            mainArr.push(arr);
                        } else {
                            mainArr.push(innerArr)
                        }
                    }
                    this.collectionPreviewListItems = mainArr;
                } else {
                    // this.$cookies.remove('token');
                    // this.$router.push({
                    //     name: "AdminLogin",
                    // });
                }
            } catch (error) {
                console.error(error);
                if(error && error.response && error.response.status == 401) {
                    window.alert("Sorry !! You are unauthorized !!");
                    this.$cookies.remove('token');
                    this.$router.push({
                        name: "AdminLogin",
                    });
                }
            }
        },
        async getAvailableProductsByCollections(parentCategoryID, index) {
            this.collectionPreviewListItems[index] = [];
            let collectionList = [];
            let sortOrderString = '{"sortField":"alphabetical","sortValue":"asc"}';
            collectionList.push(parentCategoryID);
            try {
                let sortOrder = JSON.parse(sortOrderString);
                let res = await axios_auth_instance_epharmacy_admin.get("/admin/getAvailableProductsByCollections",{ 
                    params:{
                        collectionList: collectionList,
                        minAmount: Number(0),
                        maxAmount: Number(1000000),
                        sortOrder: sortOrder 
                    }
                });
                if(res && res.data && res.data.data && res.data.data) {
                    let toGetCategory = res.data.data;
                    var arr = [];
                    for (let ind in toGetCategory) {
                        arr.push({
                            'id': toGetCategory[ind].product_id,
                            'name' : toGetCategory[ind].product_name,
                            'icon': toGetCategory[ind].product_icon_image,
                            'description': toGetCategory[ind].description,
                            'price': toGetCategory[ind].list_price,
                        });
                    var mainArr = [];
                        for (var [idx, innerArr] of this.collectionPreviewListItems.entries()) {
                            if (idx == index) {
                                mainArr.push(arr);
                            } else {
                                mainArr.push(innerArr)
                            }
                        }
                        this.collectionPreviewListItems = mainArr;
                    }
                } else {
                    // this.$cookies.remove('token');
                    // this.$router.push({
                    //     name: "AdminLogin",
                    // });
                }
            } catch (error) {
                console.error(error);
                if(error && error.response && error.response.status == 401) {
                    window.alert("Sorry !! You are unauthorized !!");
                    this.$cookies.remove('token');
                    this.$router.push({
                        name: "AdminLogin",
                    });
                }
            }
        },
        async getBlockItems() {
            var editBlockData = this.$props.editBlockDetails
            this.shortID = editBlockData.shortID;
            this.blockDescEN = editBlockData.blockDescEN;
            this.blockDescMM = editBlockData.blockDescMM;
            this.blockTitleEN = editBlockData.blockTitleEN;
            this.blockTitleMM = editBlockData.blockTitleMM;
            this.blockType = editBlockData.blockType;
            if(this.blockType === 'DOCTORS' || this.blockType === 'Doctors') {
                this.blockTypeListing = editBlockData.blockTypeListing;
            }
            await this.changeBlockType(this.blockType);
            this.addTabValue = editBlockData.addTabValue;
            if(this.addTabValue === 'Multiple') {
                this.multipleFlag = true;
                this.selectCollectionFlag = true;
            } 
            if(this.addTabValue === 'Single') {
                this.singleFlag = true;
                this.selectCollectionFlag = true;
            }
            this.backgroundColor = editBlockData.backgroundColor;
            this.selectBackGroundColor(editBlockData.backgroundColor);
            this.displayJumpLinkFlag = editBlockData.displayJumpLink ? "Yes" : "No";
            this.displayJumpLinkDetails = editBlockData.displayJumpLinkDetails;
            if(this.displayJumpLinkDetails) {
                this.buttonTitleEN = this.displayJumpLinkDetails.buttonTitleEN;
                this.buttonTitleMM = this.displayJumpLinkDetails.buttonTitleMM;
                this.jumpLink = this.displayJumpLinkDetails.jumpLink;
                if (this.jumpLink === 'SpecializationsPage') {
                    try {
                        this.customURLFlag = true;
                        let readSuccessData = await axios.post(process.env.VUE_APP_BACKEND_URL + "/getSpecializationByPosition", {
                            token: this.$cookies.get("token"),
                            typeOfUser: "ADMIN"
                        });
                        if(readSuccessData && readSuccessData.data && readSuccessData.data.data) {
                            this.customURLNames = readSuccessData.data.data;
                            this.customURLFlag = this.displayJumpLinkDetails.customURLFlag;
                            this.catalogPageType = '';
                            this.customURL = this.customURLFlag ? this.displayJumpLinkDetails.customURL : '';
                        } else {
                            alert('Something went wrong');
                        }
                    } catch (error) {
                        console.log('[ERROR] Error:', error);
                        alert('Something went wrong');
                    }
                } else if (this.jumpLink === 'CatalogPage') {
                    try {
                        this.customURLFlag = true;
                        let readSuccessData = await axios_auth_instance_epharmacy_admin.post("/admin/getAllCategories", {});
                        if(readSuccessData && readSuccessData.data && readSuccessData.data.data && readSuccessData.data.data.Items && readSuccessData.data.data.Items.length > 0) {
                            this.customURLNames = readSuccessData.data.data.Items;
                        } else {
                            alert('Something went wrong');
                        }
                        this.customURLFlag = this.displayJumpLinkDetails.customURLFlag;
                        this.catalogPageType = this.displayJumpLinkDetails.catalogPageType;
                        this.customURL = this.customURLFlag ? this.displayJumpLinkDetails.customURL : '';
                    } catch (error) {
                        console.log('[ERROR] Error:', error);
                        alert('Something went wrong');
                    }
                } else {
                    this.customURLFlag = false;
                    this.customURL = '';
                    this.catalogPageType = '';
                }
            }
            this.selectedIconIndex = editBlockData.selectedIconIndex;
            this.selectedIconURL = editBlockData.selectedIconURL;
            this.collectionList = editBlockData.collectionList;
            this.addTabList = this.collectionList;
            for(let [ind, col] of this.collectionList.entries()) {
                this.collectionItemValue[ind] = col.collectionItemValue;
                this.collectionItemValueName[ind] = col.collectionItemValueName;
                await this.toPreSelectcollectionPreviewList(this.collectionItemValue[ind], ind, this.blockType);
                this.collectionPreviewList[ind] = col.collectionPreviewList;
            }
            this.activeStatus = editBlockData.activeStatus;
            this.toggleImageFlag(editBlockData.selectedIconIndex);
        },
        async readAllSpecializations() {
            try {
                this.collectionItems = [];
                let specializationsData = await axios.post(process.env.VUE_APP_BACKEND_URL + "/readAllSpecializationsByAdmin",
                {
                    token: this.$cookies.get("token"),
                    typeOfUser: "ADMIN"
                });
                if(specializationsData && specializationsData.data && specializationsData.data.data) {
                    for(let [ind, spl] of specializationsData.data.data.entries()) {
                        this.collectionItems.push({
                            'catID': spl._id,
                            'catName': spl.specialization_name
                        });
                    }
                }
            } catch (error) {
                console.log('[ERROR] Error:', error);
                alert('Something went wrong');
            }
        },
        async getDoctorsBySpecializationList(sid, index) {
            try {
                let arr = [];
                let specializationList = [];
                this.collectionPreviewListItems[index] = [];
                specializationList.push(sid);
                let queryParams = {
                    token: this.$cookies.get("token"),
                    typeOfUser: "ADMIN",
                    specializationList: JSON.stringify(specializationList)
                };
                let getDoctorsBySpecializationData = await axios.get(process.env.VUE_APP_BACKEND_URL + "/getDoctorsBySpecializationList", {
                    params: queryParams
                });
                if(getDoctorsBySpecializationData && getDoctorsBySpecializationData.data && getDoctorsBySpecializationData.data.data) {
                    for(let [ind, spl] of getDoctorsBySpecializationData.data.data.entries()) {
                        arr.push({
                            'id': spl._id,
                            'doctorId': spl.doctor_id,
                            'name' : spl.doctor_name,
                            'icon': spl.doctor_profile_picture,
                            'description': spl.specialist,
                            'price': parseInt(spl.service_fee)+parseInt(spl.commission),
                        });
                    }
                    var mainArr = [];
                    for (var [idx, innerArr] of this.collectionPreviewListItems.entries()) {
                        if (idx == index) {
                            mainArr.push(arr);
                        } else {
                            mainArr.push(innerArr)
                        }
                    }
                    this.collectionPreviewListItems = mainArr;
                }
            } catch (error) {
                console.log('[ERROR] Error:', error);
                alert('Something went wrong');
            }
        }
    },
    async mounted() {
        this.selectIcons = [];
        for (let [index, icon] of this.$props.iconObjects.entries()) {
            this.selectIcons.push({
                id: index, 
                url: this.cloudFront + icon,
                showImage: false,
            });
        }
        this.addNewTab = this.cloudFront + this.addNewTab;
        this.tickBlue = this.cloudFront + this.tickBlue;
        this.draggableIcon =  this.cloudFront + this.draggableIcon;
        this.routeNames = this.getRouteNames();
        if (this.$props.editBlockFlagProps) {
            await this.getBlockItems();
        } else {
            this.blockDescEN = '';
            this.blockDescMM = '';
            this.blockTitleEN = '';
            this.blockTitleMM = '';
            this.backgroundColor = '';
            this.selectedIconIndex = '';
            this.displayJumpLinkFlag = 'No';
            this.collectionItemValue = [];
            this.collectionPreviewList = [];
            this.addTabList = [];
            this.displayJumpLinkDetails = {};
            this.jumpLink = '';
            this.activeStatus = false;
            this.buttonTitleEN = '';
            this.buttonTitleMM = '';
            this.catalogPageType = '';
            this.customURLFlag = false;
            this.customURL = '';
        }
    },
  };
</script>

<style scoped>
.custom-append-icon .v-input__icon--append {
  background-image: url("https://s3iconimages.mymedicine.com.mm/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.blockBorder {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}
.mtba {
    margin-top: auto;
    margin-bottom: auto;
}
.mlra {
    margin-left: auto;
    margin-right: auto;
}
.colorBlue {
    color: #48ACEF;
}
.uploadBlockButtonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    width: 117px;
    height: 36px;
    color: #FFFFFF;
    background: #48ACEF;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.saveDiscardButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 16px;
    width: 176px;
    height: 48px;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.discardButtonFont {
    color: #828282;
}
.saveButtonFont {
    color: #FFFFFF;

}
.btnClass {
  background: transparent !important;
  color: rgb(228, 26, 26) !important;
  margin-right: -8%;
  font-size: 12pt !important;
}
.mtb20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.height80 {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 80px;
}

.height200 {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 196.38px;
}
.collapsible {
    cursor: pointer;
    padding: 18px;
    background-color: #f1f1f1;
    border: none;
    text-align: left;
    outline: none;
    width: 100%;
}

.content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #ffffff;
}

.sinMulText {
    font-family: 'Inter';
    font-style: normal;
    line-height: 19px;
}
.sinMulCommon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    justify-content: center;
    width: 189px;
    height: 48px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.sinMulSelected {
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #48ACEF;
    color: #48ACEF;
}
.sinMulNotSelected {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #E0E0E0;
    color: #333333;
}
.maxFont {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: right;
}
.addNewTabStyle {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 16px;
    gap: 10px;
    width: 1347px;
    height: 48px;
    border: 1px dashed #48ACEF;
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 1;
}
.height225 {
    height: 225px;
}
.height76 {
    height: 225px;
}
.addNewTabCollectionStyle {
    box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 16px;
width: 1171px;
height: 225px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
border-radius: 8px;
flex: none;
order: 1;
flex-grow: 1;
}
.iconDivClass {
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border: 1px dashed #BDBDBD;
    border-radius: 4px;
    /* height: 80px;
    flex: none; 
    order: 1;
    flex-grow: 0; */
}
.iconDivClassWidth80 {
    width: 80px;
}
.iconDivClassWidth120 {
    width: 120px;
}
.inter400Normal {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
}
.colorClass {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 16px;
    width: 182px;
    height: 64px;
    border-radius: 4px;
    flex: none;
    background: #FFFFFF;
    order: 0;
    flex-grow: 0;
}
.colorClassSelected {
    border: 1px solid #48ACEF;
    color: #48ACEF;
}
.colorClassNotSelected {
    border: 1px solid #E0E0E0;
    color: #4F4F4F;
}
.discardBorder {
    border: 1px solid #828282; border-radius: 6px;
}
.borderBlue {
    border: 1px solid #48ACEF; border-radius: 6px;
}
.v-text-field__details {
min-height: 0px !important
}
</style>