<template>
	<v-dialog style="background-color: #FFFFFFF" @keydown="handleKeyDown" v-model="dialog" persistent width="1467px">
			<v-card>
				<v-card-title style="font-family: Inter;font-size: 28px;font-weight: 600;line-height: 34px;letter-spacing: 0em;text-align: left;">
					Builder
				</v-card-title>

				<v-card-text style="font-family: Inter;font-size: 16px;font-weight: 400;line-height: 19px;letter-spacing: 0em;text-align: left;">
					Site Preview
				</v-card-text>
				<v-divider></v-divider>
				<!-- <v-card-actions class="d-flex-column justify-center align-center" style="width: 1462px; height: 649px; border-radius: 8px; border: 1px"> -->
					<div class="d-flex-column justify-center align-center" style="width: 100%; min-height: 649px; border-radius: 8px; border: 1px">

						<!-- Navigation Bar Segment -->
						<div class="container">
							<!-- Loader Web -->
							<div v-if="skeletonLoaderFlag" class="loader-web">
								<!-- Banner Segment -->
								<div class="banners-segment">
									<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
								</div>

								<!-- MyMed Features Segment -->
								<div class="feature-tabs-container">
									<div class="pa-4">
										<v-skeleton-loader elevation="0" type="heading" class="d-flex justify-center align-center"></v-skeleton-loader>
									
										<div class="pt-4 d-flex justify-space-between">
											<v-skeleton-loader elevation="0" type="image" width="600" class="pr-2"></v-skeleton-loader>

											<v-skeleton-loader elevation="0" type="image" width="600" class="pl-2"></v-skeleton-loader>
										</div>
									</div>
								</div>

								<!-- Explore By Specialties Segment -->
								<div class="specialties-segment">
									<explore-specialities-component @handleCategorySizeChange="handleCategorySizeChange" :key="specializationComponentKey" category_type="Teleconsultation" :header_data="exploreSpecHeader"></explore-specialities-component>
								</div>

								<!-- Dynamic Segments -->
								<div class="pb-5 banners-segment">
									<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
								</div>

								<div class="products-segment">
									<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

									<div class="d-flex">
										<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
										<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
									</div>

									<div class="d-flex">
										<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
										<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
									</div>
								</div>

								<div class="pa-4">
									<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

									<div class="d-flex">
										<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
										<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
									</div>

									<div class="d-flex flex-column">
										<div class="py-2 d-flex">
											<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
											<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
										</div>
										
										<div class="py-2 d-flex">
											<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
											<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
										</div>
									</div>
								</div>

								<div class="pb-5 banners-segment">
									<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
								</div>
							</div>
							<!-- Loader Mobile -->
							<div v-if="skeletonLoaderFlag" class="loader-mobile">
								<!-- Banner Segment -->
								<div class="banners-segment">
									<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
								</div>

								<!-- MyMed Features Segment -->
								<div class="feature-tabs-container">
									<div class="pa-4">
										<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>
									
										<div class="pt-4 d-flex justify-space-between">
											<v-skeleton-loader elevation="0" type="image" width="600" height="100" class="pr-2"></v-skeleton-loader>

											<v-skeleton-loader elevation="0" type="image" width="600" height="100" class="pl-2"></v-skeleton-loader>
										</div>
									</div>
								</div>

								<!-- Explore By Specialties Segment -->
								<!-- <div class="specialties-segment">
									<explore-specialities-component @handleCategorySizeChange="handleCategorySizeChange" :key="specializationComponentKey" :header_data="exploreSpecHeader"></explore-specialities-component>
								</div> -->

								<!-- Dynamic Segments -->
								<div class="pb-5 banners-segment">
									<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
								</div>

								<div class="products-segment">
									<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

									<div class="d-flex">
										<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
										<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
									</div>

									<div class="d-flex">
										<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
										<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
									</div>
								</div>

								<div class="pa-4">
									<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

									<div class="d-flex">
										<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
										<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
									</div>

									<div class="d-flex flex-column">
										<div class="py-2 d-flex">
											<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
											<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
										</div>
										
										<div class="py-2 d-flex">
											<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
											<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
										</div>
									</div>
								</div>

								<div class="pb-5 banners-segment">
									<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
								</div>
							</div>

							<div v-if="!skeletonLoaderFlag">
								<!-- Banner Segment -->
								<div class="banners-segment">
									<banner :key="bannersComponentKey" :banners="banners" banner_type="BANNERS-ONE-PER-SLIDE"></banner>
								</div>

								<!-- MyMed Features Segment -->
								<div class="feature-tabs-container">
									<div class="pa-4">
										<p class="body-heading font-weight-semi-bold feature-tabs-title">What are you looking for?</p>
									
										<div class="feature-tabs-segment">
											<div class="pa-4 ePharmacy-tab feature-tab">
												<div class="display-mobile">
													<div class="d-flex justify-center align-center">
														<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/ePharamcyIconHome.svg">
													</div>
												</div>

												<div class="display-web">
													<div class="d-flex justify-flex-start">
														<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/ePharamcyIconHome.svg">
													</div>
												</div>
												
												<p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text">Order Medicine</p>
											</div>

											<div class="pa-4 teleconsultation-tab feature-tab">
												<div class="display-mobile">
													<div class="d-flex justify-center align-center">
														<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/teleconIconHome.svg">
													</div>
												</div>

												<div class="display-web">
													<div class="d-flex justify-flex-start">
														<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/teleconIconHome.svg">
													</div>
												</div>
												<p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="center">Book Appointments</p>
											</div>
										</div>
									</div>
								</div>

								<!-- Explore By Specialties Segment -->
								<div class="specialties-segment">
									<div v-if="specializations.length > 0">
										<explore-specialities-component @handleCategorySizeChange="handleCategorySizeChange" :key="specializationComponentKey" :categories="specializations" :category_type=" this.$props.previewOnBuilderProps['homepageType'] == 'EPHARMACY' ? 'Epharmacy' : 'Teleconsultation' " :header_data="exploreSpecHeader"></explore-specialities-component>
									</div>
								</div>
								

								<!-- Dynamic Segments -->
								<div v-for="segment, index in segmentComponents" :key="index">
									<div v-if="segment.type === 'BANNERS-ONE-PER-SLIDE' || segment.type === 'BANNERS-THREE-PER-SLIDE'" class="pb-5 banners-segment">
										<div>
											<banner :key="bannersComponentKey" :banners="segment.items" :banner_type="segment.type"></banner>
										</div>
									</div>

									<div class="products-segment" v-if="segment.type === 'LISTINGS-ONE-ROW'">
										<div>
											<listings-component
												:data="segment"
												listings_type="LISTINGS-ONE-ROW"
											></listings-component>
										</div>
									</div>

									<div class="products-segment" v-if="segment.type === 'LISTINGS-TWO-ROWS'">
										<div>
											<listings-component
												:data="segment"
												listings_type="LISTINGS-TWO-ROWS"
											></listings-component>
										</div>
									</div>

									<div class="products-segment" v-if="segment.type === 'ARTICLES'">
										<listings-component
											:data="segment"
											listings_type="ARTICLES"
										></listings-component>
									</div>
								</div>
							</div>
						</div>

						<div class="display-web">
							<v-divider></v-divider>
						</div>
					</div>
					<div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: end;">
						<v-btn class="mx-3 saveDiscardButton discardButtonFont" style="width: 176px; background: #FFFFFF; color:#828282;" @click="discardDialog()">Back to Editor</v-btn>
						<v-btn class="mx-3 saveDiscardButton saveButtonFont" style="width: 176px; background: #219653; color: #FFFFFF;" @click="PublishCHP()">
							<span class="mr-4">
								Publish
							</span>
							<img :src="publishWhiteIcon" />
						</v-btn>
					</div>
			</v-card>
	</v-dialog>
</template>

<script>
import { axios_auth_instance_epharmacy_admin } from '../utils/axios_utils';

export default {
	name: 'PreviewOnBuilder',
    props: ['previewOnBuilderProps'],
	components: {
		'common-home-header': () => import('../components/navigationComponents/commonHomeHeader.vue'),
		'header-tabs-component': () => import('../components/navigationComponents/headerTabsComponent.vue'),
		'banner': () => import('../components/ePharmacyComponents/bannersHomeComponent.vue'),
		'explore-specialities-component': () => import('../components/ePharmacyComponents/categoryHomePageComponent.vue'),
		'listings-component': () => import('../components/ePharmacyComponents/multipleStyleListingsComponent.vue'),
		'bottom-navigation-bar': () => import('../components/navigationComponents/bottomNavigationBar.vue'),
		'common-home-footer': () => import('../components/navigationComponents/commonHomeFooter.vue')
	},
	data () {
		return {
			publishWhiteIcon: '',
            dialog: false,
			screenWidth: window.innerWidth,
			skeletonLoaderFlag: false,
			cart_quantity: null,
			banners: [],
			segmentComponents: [],
			bannersComponentKey: 0,
			specializations: [],
			specializationComponentKey: 0,
			categories: [],
			categoriesComponentKey: 0,
			productsList: [],
			productsList3: [],
			exploreSpecHeader: {},
			exploreByHealthConcern: {},
			sliderSegmentData1: null,
			sliderSegmentData2: null,
			sliderSegmentData3: null,
			sliderSegmentData4: null,
			productSliderData1: null,
			productSliderData2: null,
			bgColorOfComponent: '',
			buttonTextForComponent: '',
			cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
		}
	},
	watch: {
		screenWidth(newWidth, oldWidth) {
		},
	},
	mounted() {
		this.publishWhiteIcon = this.cloudFront + 'publishWhiteIcon.svg';
        this.dialog = this.$props.previewOnBuilderProps.showPreviewOnBuilder;
		window.addEventListener('resize', this.handleResize);

		// Banners Segment.
		this.banners = this.$props.previewOnBuilderProps.heroBannerCacheData;

		// Specializations Segment.
		this.exploreSpecHeader = {
			icon: 'https://s3iconimages.mymedicine.com.mm/explore_by_specialities_home_icon.svg',
			heading_name: 'Explore By Specialities',
			pagename: 'SpecializationsPage'
		};

		this.specializations = this.$props.previewOnBuilderProps.specializations;

		this.segmentComponents = this.sortedArray(this.$props.previewOnBuilderProps.segmentCacheData);

		this.skeletonLoaderFlag = false;
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		sortedArray(newItem){
			return newItem.sort((a, b) => a.segment_order - b.segment_order );
		},
		clickNo() {
			this.dialog = false;
			this.$emit("closePreviewDialog", this.dialog);
        },
		PublishCHP() {
			axios_auth_instance_epharmacy_admin.post('/admin/publishHomePage', {
				heroBannerListDetailsRequest: this.$props.previewOnBuilderProps.heroBannerCacheData,
				segmentsListDetailsRequest: this.segmentComponents,
				specializationsDetailsRequest: this.$props.previewOnBuilderProps.specializations,
				pageType: this.$props.previewOnBuilderProps['homepageType']
			}).then((commonHomePageResponse) => {
				this.dialog = false;
				this.$emit("closePreviewDialogOnSuccess", this.dialog);
			}).catch((error) => {
				console.error('error', error);
			});
		},
		discardDialog() {
			this.clickNo();
		},
		handleKeyDown(event) {
            if (event.keyCode === 27) {
                this.clickNo();
            }
        },
		handleResize() {
			this.skeletonLoaderFlag = true;
			this.screenWidth = window.innerWidth;
			this.bannersComponentKey++;
			this.specializationComponentKey++;
			this.categoriesComponentKey++;			
			this.skeletonLoaderFlag = false;
		},
		handleCategorySizeChange() {
			this.skeletonLoaderFlag = true;
			this.specializationComponentKey++;
			this.skeletonLoaderFlag = false;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../scss/global.scss';
@import '../scss/classes.scss';
.loader-web, .footer-segment, .navigation-tabs-segment, .display-web {
	@media (max-width: 600px) {
        display: none !important;
	}
}

.loader-mobile, .mobile-bottom-nav-bar, .display-mobile {
	@media (max-width: 600px) {
        
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none !important;
	}

	@media (min-width: 1265px) {
		display: none !important;
	}
}
.banners-segment {
	width: 100%;
	height: auto;

	@media (max-width: 600px) {
        padding-top: $content-padding-mobile;
		padding-bottom: calc( $content-padding-mobile / 2);
		padding-left: $margin-header-x-mobile;
		padding-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: $content-padding-medium;
		padding-bottom: calc( $content-padding-medium / 2 );
		padding-left: $margin-header-x-medium;
		padding-right: $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: calc( $content-padding-web / 2 );
		padding-left: $margin-header-x-web;
		padding-right: $margin-header-x-web;
	}
}
.specialties-segment {
	@media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: calc( $content-padding-mobile / 2);
		margin-left: $margin-header-x-mobile;
		margin-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: $content-padding-medium;
		padding-bottom: $content-padding-medium;
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;

		border-radius: 8px;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: $content-padding-web;
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;

		border-radius: 8px;
	}
}
.products-segment {
	@media (max-width: 600px) {
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: calc($content-padding-medium / 2);
		padding-bottom: calc($content-padding-medium / 2);
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: $content-padding-web;
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;
	}
}
.feature-tabs-container {
	background-color: #F4F6FA;

	@media (max-width: 600px) {
        margin-top: calc($content-padding-mobile / 2);
		margin-bottom: calc($content-padding-mobile / 2);
		padding-left: $margin-header-x-mobile;
		padding-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		margin-top: calc($content-padding-medium / 2);
		margin-bottom: calc($content-padding-medium / 2);
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;

		border-radius: 8px;
	}

	@media (min-width: 1265px) {
		margin-top: calc($content-padding-web / 2);
		margin-bottom: calc($content-padding-web / 2);
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;

		border-radius: 8px;
	}
}

.promo-banner-segment {
	@media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: calc( $content-padding-mobile / 2);
		padding-left: $margin-header-x-mobile;
		padding-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: calc($content-padding-medium / 2);
		padding-bottom: calc($content-padding-medium / 2);
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		padding-top: calc($content-padding-web / 2);
		padding-bottom: calc($content-padding-web / 2);
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;
	}
}
.feature-tabs-title {
	@media (max-width: 600px) {
        display: flex;
		justify-content: flex-start;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (min-width: 1265px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.feature-tabs-segment {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: 600px) {
        width: 100%;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		width: 60%;
	}

	@media (min-width: 1265px) {
		width: 60%;
	}
}
.feature-tab {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 4px;
	padding: 8px;
}

.feature-text {
	color: white;

	@media (max-width: 600px) {
		text-align: center;
	}

	@media (min-width: 601px) and (max-width: 1520px) {
		text-align: left;
	}

	@media (min-width: 1521px) {
		text-align: left;
	}
}
.teleconsultation-tab {
	width: 49%;
	background-color: #F89035;
}
.ePharmacy-tab {
	width: 49%;
	background-color: #1467BF;
}
.icon-class {
	padding-bottom: 8px;
	object-fit: fill;

	@media (max-width: 600px) {
		width: 32px;
		height: 32px;
		justify-content: center;
	}

	@media (min-width: 601px) {
		width: 45px;
		height: 45px;
		justify-content: left;
	}
}
.banner-image {
	width: 100%;
    height: 100%;
    object-fit: fill;
}

.search-container, .search-segment {
	width: 100%;
}
.search-bar {
	border-radius: 8px;
}
</style>

<style lang="scss">
@import '../scss/global.scss';
@import '../scss/classes.scss';

.navigation-tabs-segment .v-tab {
	text-transform: none;
}
</style>